<template>
  <div name="exam-content">
    <section name="questions">
      <question-component
        v-for="(question, index) in questions"
        :key="`q_${question.id}`"
        :question="question"
        :index="index"
        :correctFeedback="correctFeedback"
        @onAlternativeClick="handleAlternativeClick"
      />
    </section>
    <section name="actions" class="flex-right mt-2">
      <ui-link type="text" @click="handleBackClick">
        {{ $t('actions.cancel') }}
      </ui-link>
      <ui-link type="primary" class="ml-4" @click="handleCheckClick">
        {{ $t('actions.check_answers') }}
      </ui-link>
      <ui-link
        type="success"
        class="ml-4"
        :disabled="!canSubmit"
        @click="handleSubmitClick"
      >
        {{ $t('actions.complete') }}
      </ui-link>
    </section>
  </div>
</template>

<script>
import QuestionComponent from './QuestionComponent';
import UiLink from '@/components/ui/UiLink';
export default {
  props: {
    questions: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      correctFeedback: false,
      canSubmit: false,
      currentAnswers: [],
      needNumber: 8,

    };
  },

  components: {
    QuestionComponent,
    UiLink
  },

  methods: {
    handleBackClick() {
      this.$router.back();
    },

    handleCheckClick() {
      this.correctFeedback = true;
      const numberCorrect = this.currentAnswers.filter(
        a => a.isCorrect === true
      ).length;
      if (numberCorrect >= this.needNumber) this.canSubmit = true;
    },

    handleSubmitClick() {
      if (!this.canSubmit) {
        return;
      }
      this.$emit('onCompleteExam');
    },

    handleAlternativeClick(questionIndex, currentAlternative) {
      this.correctFeedback = false;
      this.currentAnswers[questionIndex] = currentAlternative;
      this.canSubmit = false;
    }
  },

  created() {
    const questionNumber = this.questions.length;
    const initAnswers = [];
    for (let i = 0; i < questionNumber; i++) {
      initAnswers.push({});
    }
    this.currentAnswers = initAnswers;

    if (this.needNumber > this.questions.length)
      this.needNumber = this.questions.length;
  }
};
</script>

<style>
.flex-right {
  display: flex;
  justify-content: right;
}
</style>
