import norwegian from "./lang/no.json"
import english from "./lang/en.json"
import {forEach} from "ramda";
import el from "element-ui/src/locale/lang/el";


export function translate(location, language) {
    let translated = ""
    if (language === "no") {
        translated = getWordFromFile(norwegian, location);

    } else if (language === "en") {
        translated = getWordFromFile(english, location);
    }
    return translated;
}

function getWordFromFile(file, location) {

    let result = file

    location.forEach((values) => {
        result = result[values]
    })


    return result

}
