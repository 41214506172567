<template>
  <div>
    <label for="questionText"
      >{{ $tc('models.question', 1) }} {{ index + 1 }}</label
    >
    <rich-text-field
      name="questionText"
      v-model="question.text.filter(t => t.lang === $i18n.locale)[0].value"
      :hideBorder="true"
      :showEditor="false"
    />
    <section name="alternatives" class="flex-column">
      <label>{{ $tc('models.alternative', 2) }}</label>
      <alternative-component
        v-for="(alternative, altIndex) in alternatives"
        :key="`q_${question.id}_a_${alternative.id}`"
        :alternative="alternative"
        :index="altIndex"
        :correctFeedback="correctFeedback"
        :selectedAlternative="selectedAlternative"
        @onClick="onAlternativeClick"
        class="alternative"
      />
    </section>
  </div>
</template>

<script>
import RichTextField from '@/components/form/RichTextField';
import AlternativeComponent from './AlternativeComponent';
export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    correctFeedback: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      alternatives: null,
      selectedAlternative: null,
    };
  },

  components: {
    RichTextField,
    AlternativeComponent
  },

  methods: {
    onAlternativeClick(alternative) {
      this.selectedAlternative = alternative;
      this.$emit('onAlternativeClick', this.index, alternative);
    }
  },

  created() {
    this.alternatives = this.question.alternatives;
  }
};
</script>

<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.alternative {
  margin-bottom: 1em;
  width: 100%;
  text-align: left;
  font-weight: 600;
  color: black;
}
</style>
